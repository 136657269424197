<template>
  <fragment>
    <v-menu
      v-if="showSelectGroup()"
      v-show="selectedDataGroup.id"
      bottom
      left
      min-width="200"
      max-width="400"
      max-height="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="selectedDataGroup.id"
          text
          v-on="on"
        >
          <img
            :src="selectedDataGroup.image_url"
            class="mr-2"
            width="23"
            height="17"
          >
          <span
            style="font-size: 17px"
            v-text="selectedDataGroup.title"
          />
        </v-btn>
      </template>

      <v-list
        flat
        nav
      >
        <v-btn
          v-for="(item, itemIndex) in dataGroups" :key="itemIndex + '_dataGroup'"
          text
          width="100%"
          class="justify-start"
          @click="setSelectedGroupId(item.id)"
        >
          <img
            :src="item.image_url"
            class="mr-2"
            width="21"
            height="15"
          >
          <span
            style="font-size: 17px"
            v-text="item.title"
          />
        </v-btn>
      </v-list>
    </v-menu>
    <v-tooltip v-if="isRoleUserLogin()" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="isShowDialogJoinGroup = true"
        >
          <v-icon>mdi-group</v-icon>
        </v-btn>
      </template>
      <span>Join Group</span>
    </v-tooltip>
    <v-dialog
      v-model="isShowDialogJoinGroup"
      persistent
      max-width="90%"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="headline">Join Group</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="dataForm.groupId"
              :rules="groupIdRules"
              :append-icon="showGroupId ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showGroupId ? 'text' : 'password'"
              label="Group Id"
              outlined dense
              @click:append="showGroupId = !showGroupId" />
            <v-text-field
              v-model="dataForm.groupSecret"
              :rules="groupSecretRules"
              :append-icon="showGroupSecret ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showGroupSecret ? 'text' : 'password'"
              label="Group Secret"
              outlined dense
              @click:append="showGroupSecret = !showGroupSecret" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue-grey"
              @click="isShowDialogJoinGroup = false"
              v-text="$t('user.userForm.actions.close')"
            />
            <v-btn
              color="info"
              :disabled="!valid"
              @click="save"
              v-text="$t('user.userForm.actions.save')"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </fragment>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'DefaultSelectGroup',
  data: () => ({
    isShowDialogJoinGroup: false,
    showGroupId: false,
    showGroupSecret: false,
    valid: true,
    groupIdRules: [
      (v) => !!v || 'Group Id is required',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
    groupSecretRules: [
      (v) => !!v || 'Group Secret is required',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
    dataForm: {
      groupId: '',
      groupSecret: '',
    },
    selectedDataGroup: {},
  }),
  computed: {
    ...get('userProfile', [
      'dataProfile',
    ]),
    pageName: get('route/name'),
    dataGroups() {
      return this.dataProfile?.dataGroups || []
    },
  },
  watch: {
    isShowDialogJoinGroup: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.initData()
        }
      },
      deep: true,
    },
    dataProfile: {
      handler (newDataProfile, oldVal) {
        const dataGroups = newDataProfile?.dataGroups || []
        if (!dataGroups.length) {
          return
        }
        let selectedGroupId = localStorage.getItem('groupId')
        if (!selectedGroupId) {
          selectedGroupId = dataGroups[0]?.id
          localStorage.setItem('groupId', selectedGroupId)
        }
        const foundGroup = dataGroups.find(dataGroup => dataGroup.id === selectedGroupId)
        if (!foundGroup) {
          this.setSelectedGroupId(dataGroups[0]?.id)
          return
        }
        this.selectedDataGroup = foundGroup
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showSelectGroup() {
      const pageNotShowSelectGroup = ['AdminGroup', 'AdminUser']
      return !pageNotShowSelectGroup.includes(this.pageName)
    },
    async initData() {
      this.showGroupId = false
      this.showGroupSecret = false
      this.dataForm = {
        groupId: '',
        groupSecret: '',
      }
    },
    async setSelectedGroupId(groupId) {
      const selectedGroupId = localStorage.getItem('groupId')
      if (selectedGroupId !== groupId) {
        localStorage.setItem('groupId', groupId)
        location.href = '/'
      }
    },
    async save () {
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return
      }
      const resultJoinGroup = await this.$store.dispatch('adminUser/joinGroup', {
        tokenLogin: this.getTokenLogin(),
        dataForm: this.dataForm,
      })
      if (!resultJoinGroup?.status) {
        this.$toast.error(resultJoinGroup?.data?.message || 'There is a problem with the system, please try again later')
        return
      }
      this.$toast.success(resultJoinGroup?.data?.message)
      this.isShowDialogJoinGroup = false
      location.reload()
    },
  },
}
</script>
